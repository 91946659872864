import React, { Component } from 'react';
import axios from 'axios';
import '../stile/App.css';
import CheckLogin from './CheckLogin';
import { HOST } from '../costanti.js';

class Aggiungi extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            nome: "",
            categoria: "",
            preso: "nonpreso",
            usato: "nonusato",
            check1: false,
            check2: false,
            listaCategorie: [],
        }
    }

    componentDidMount() {
        //this.getCategorie()
        //console.log("component did mount")
    }

    //TODO da modificare prima o poi
    UNSAFE_componentWillMount(){
        this.getCategorie()
        //console.log("component will mount")
    }
    


    CbxPreso() {
        if (this.state.preso === "nonpreso"){
            this.setState({ 
                preso: "preso",
                check1: true
            })
        } else {
            this.setState({ 
                preso: "nonpreso",
                check1: false
            })
        }
    }

    CbxUsato() {
        if (this.state.usato === "nonusato") {
            this.setState({ 
                usato: "usato",
                check2: true
            })
        } else {
            this.setState({ 
                usato: "nonusato",
                check2: false
            })
        }
    } 

    itemAggiungi() {
        
        axios.get(HOST + '/api/itemAggiungi.php?nome=' + this.state.nome + '&categoria=' + this.state.categoria + '&preso=' + this.state.preso + '&usato=' + this.state.usato + '&nomelista=' + sessionStorage.getItem("nomelista"))
            .then(response => {

                if (response.data.return) {
                    console.log("Aggiunto item " + this.state.nome)
                    alert("Il nuovo item " + this.state.nome + " è stato aggiunto alla lista")
                    this.setState({
                        nome: "",
                        categoria: "",
                        preso: "nonpreso",
                        usato: "nonusato",
                        check1: false,
                        check2: false
                    })
                    this.getCategorie()
                } else {
                    alert(response.data)
                }
            })
    }

    getCategorie(){
        this.setState({ listaCategorie: [] })
        axios.get(HOST + '/api/getCategorie.php?nomelista=' + sessionStorage.getItem("nomelista"))
            .then(response => {
                if (response.data.return) {

                    for (let i = 0; i < response.data.categorie.length; i++){
                        let id = response.data.categorie[i].id
                        let nome = response.data.categorie[i].cat
                        this.state.listaCategorie.push({id: id, categoria: nome})
                    }
                    this.setState({ listaCategorie: this.state.listaCategorie })

                } else {
                    console.log(response.data)
                }
            })
            .catch(error => {
                if (error.response){
                    console.log(error.response)
                }
            })
    }

    render() {
        var usernameEsiste = sessionStorage.getItem("username")
        var utenteLoggato = sessionStorage.getItem("isUserLogged")

        if (usernameEsiste === null && utenteLoggato === null) {

            return (
                <div>
                    <CheckLogin />
                </div>
            );

        } else {
            //axios.defaults.withCredentials = true
            
            return (

                <div>
                    
                    <form action="#" className={this.props.stile}>
                        <span style={{fontWeight: 'bold'}}>AGGIUNGI UN ITEM</span><br/>
                        <label>Categoria</label><br />
                        <select
                            name="categorie"
                            id="categoria"
                            value={this.state.categoria}
                            onChange={(e) => this.setState({ categoria: e.target.value })}
                            className="inputAggiungi"
                            required >
                            <option value="NULL"
                                defaultInputValue="defaultInputValue"> Scegli la categoria
                            </option>

                            {this.state.listaCategorie.map((element, index) => {
                                return <option  key={index} value={element.id}>
                                    {element.id} - {element.categoria}
                                </option>
                            })}
                        </select>

                        <br />
                        <label>Nome</label><br />
                        <input type="text" id="nome" name="nome"
                            value={this.state.nome}
                            maxLength="50"
                            //onFocus={(e) => this.getCategorie()}
                            onChange={(e) => this.setState({ nome: e.target.value })}
                            className="inputAggiungi"
                            required />
                        <br />

                        
                        <span className="inputAggiungi"><input type="checkbox" value={this.state.preso} 
                            onChange={() => this.CbxPreso()} checked={this.state.check1}/> PRESO</span><br/>
                        <span className="inputAggiungi"><input type="checkbox" value={this.state.usato} 
                            onChange={() => this.CbxUsato()} checked={this.state.check2}/> USATO</span>
                        <br/>
                        <button type="button" className="aggiungi"
                            onClick={() => this.itemAggiungi()}>AGGIUNGI</button>

                    </form>

                </div>
            );
            
        }

        
    }
}
export default Aggiungi;