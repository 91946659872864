import React from "react";
import '../stile/App.css';
import AggiungiUtente from "../components/AggiungiUtente";


function Home() {
    return (
        <div className="homepage">
            <h1>PACKING CHECKLIST</h1>
            <p style={{ textAlign: 'center'}}>
                Questo sito è la tua change di partire organizzato,<br/>
                Non dimenticherai più le tue cose
                </p>
            <p style={{ textAlign: 'center' }}>
                Puoi registrare tutti gli account che vuoi,<br/>
                Un account può gestire una sola lista
            </p>
            <AggiungiUtente />
            <div style={{visibility: 'hidden'}}>
                <span>icons: https://www.flaticon.com/authors/pixel-perfect/rounded-shapes</span>
            </div>
            
        </div>
    );
}

export default Home;