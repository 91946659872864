import React from "react";
import '../stile/App.css';
import Lista from "../components/Lista";


function Dashboard() {

    return (
        <div className="dashboard">
            <Lista />
        </div>
    );
}

export default Dashboard;