import React, { Component } from 'react';
import axios from 'axios';
import '../stile/App.css';
import { HOST } from '../costanti.js';


class AggiungiUtente extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nome: "",
            email: "",
            password: "",
            password2: "",
            lista: "",
            feedback: null
        }


    }
    shouldComponentUpdate(prevState, nextState){
        if(prevState.nome === nextState.nome){
            return false
        } else {
            //render
            this.checkLista()
            return true
        }
    }

    checkLista(){
        if (this.state.lista === "") {
            var random = Math.floor(Math.random() * (10000000 - 1))
            var nomelista = "lista_" + random
            this.setState({
                lista: nomelista
            })
        }
    }


    utenteAggiungi() {
        if (this.state.password === this.state.password2){
            this.setState({
                feedback: null
            })
            
            if(this.state.lista !== ""){
                axios({
                method: 'post',
                url: HOST + '/api/utenteAggiungi.php',
                headers: { 'content-type': 'application/json' },
                data: this.state
                })
                .then(response => {

                if (response.data.return) {
                    var ris3 = <p>La registrazione è andata a buon fine!</p>
                    console.log("aggiunto new user")
                    this.setState({
                        nome: "",
                        email: "",
                        password: "",
                        password2: "",
                        feedback: ris3
                    })

                    
                    this.utenteDB()


                } else {
                    console.log(response.data)
                    var ris2 = <p>Qualcosa è andato storto, riprovare...</p>
                    this.setState({
                        nome: "",
                        email: "",
                        password: "",
                        password2: "",
                        feedback: ris2
                        })
                    }
                })

            } 
                
        } else {
            var ris = <p>Le password non corrispondono, riprovare!</p>
            this.setState({
                feedback: ris
            })
        }
        
    }


    utenteDB(){
        //seconda chiamata al database
        axios({
            method: 'post',
            url: HOST + '/api/utenteDatabase.php',
            headers: { 'content-type': 'application/json' },
            data: this.state
        })
            .then(response => {

                if (response.data.return) {
                    console.log("aggiunto il DB " + this.state.lista)
                    this.setState({
                        lista: ""
                    })
                } else {
                    console.log("utenteDatabase call non funge " + this.state.lista)
                    this.setState({
                        lista: ""
                    })
                }
            })
    }

    render() {
            return (

                <div className="divRegistrazione">

                    <form action="#" className="formRegistrazione">
                        <p style={{ fontWeight: 'bold' }}>Crea un nuovo account!</p>
                        <label>Username*</label>
                        <input type="text" id="nome" name="nome"
                            value={this.state.nome}
                            maxLength="20"
                            onChange={(e) => this.setState({ nome: e.target.value })}
                            className="inputAggiungi"
                            placeholder="Username"
                            required />
                        

                        <label>Email</label>
                        <input type="text" id="email" name="email"
                            value={this.state.email}
                            maxLength="40"
                            onChange={(e) => this.setState({ email: e.target.value })}
                            className="inputAggiungi"
                            placeholder="Email address"
                        />
                        

                        <label>Password*</label>
                        <input type="password" id="password" name="password"
                            value={this.state.password}
                            maxLength="20"
                            onChange={(e) => this.setState({ password: e.target.value })}
                            className="inputAggiungi"
                            placeholder="Password"
                            required
                        />
                        

                        <label>Ripeti la Password*</label>
                        <input type="password" id="password2" name="password2"
                            value={this.state.password2}
                            maxLength="20"
                            onChange={(e) => this.setState({ password2: e.target.value })}
                            className="inputAggiungi"
                            placeholder="Repeat password"
                            required
                        />
                        

                        <label>Nome della lista</label>
                        <input type="text" id="lista" name="lista"
                            value={this.state.lista}
                            maxLength="20"
                            onChange={(e) => this.setState({ lista: e.target.value })}
                            className="inputAggiungi"
                        />

                    
                        <button type="button" className="aggiungi"
                            onClick={() => this.utenteAggiungi()}>Registrati</button>

                    </form>

                    {this.state.feedback}

                </div>
            );
        


    }
}
export default AggiungiUtente;