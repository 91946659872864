import "../stile/App.css";
import React from "react";
import package_json from "../../package.json";

function Footer() {

    return (
        <footer>
            <p>
                Made with ❤
                <br/>ReactJS
                <br />2022
                <br/><b>{package_json.version}</b>
            </p>
        </footer>
    );
}

export default Footer;