import React, { Component } from 'react';
import axios from 'axios';
import '../stile/App.css';
import '../stile/AccediStile.css';
import { Link } from 'react-router-dom';
import { HOST } from '../costanti.js';
import imgAccount from '../assets/icons/account64.png';
import imgAreaPersonale from '../assets/icons/areaPersonale64.png';
import imgLista from '../assets/icons/lista64.png';
import imgAggiungi from '../assets/icons/listaCose64.png';
import imgLogout from '../assets/icons/exit64.png';

class Login extends Component {

    //the state of the login form
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            message: null,
            visible: "hidden",
            color: null,
            nomelista: "nome_lista"
        };
    }


    //form submit handler method
    tryLogin(event){
        event.preventDefault();
        
        axios({
            method: 'post',
            url: HOST + '/api/login.php',
            headers: {'content-type':'application/json'},
            data: this.state
        })
        .then(response => {
            if (response.data.login) {
                sessionStorage.setItem("nomelista", response.data.nomelista)
                sessionStorage.setItem("username", response.data.utente)
                sessionStorage.setItem("isUserLogged", true)
                this.forceUpdate()
               
            } else {
                this.setState({
                    message: response.data.message,
                    visible: "visible",
                    color: "#ff938f"
                })
            }
        })
            .catch(error => this.setState({ message: error.message}));
    }

    //how to send data from react to php api
    //install axios using npm, it works well with http requests

    deleteSession(){

        sessionStorage.removeItem("nomelista")
        sessionStorage.removeItem("username")
        sessionStorage.removeItem("isUserLogged")

        this.setState({
            username: "",
            password: ""
        })
        
        this.forceUpdate()
    }

    render() {

        var usernameEsiste = sessionStorage.getItem("username")
        var utenteLoggato = sessionStorage.getItem("isUserLogged")
        
        if (usernameEsiste === null && utenteLoggato === null) {

            return (

                <div className="containerLogin" id="loginpage">
                    <img src={imgAccount} alt="Login Page" heigth={80}/>
                <form action="#" className="formLogin">
                    <label>Username</label>
                    <input type="text" className="user" name="username"
                        value={this.state.username}
                        onChange={(e) => this.setState({ username: e.target.value })}
                        require />

                    <label>Password</label>
                    <input type="password" className="pwd" name="password"
                        value={this.state.password}
                        onChange={(e) => this.setState({ password: e.target.value })}
                        required />
                    
                    <input type="submit" value="Login" className="faiLogin"
                        onClick={(e) => this.tryLogin(e)} />
                    
                </form>
                
                <p className="error" style={{visibility:this.state.visible, backgroundColor:this.state.color}}>{this.state.message}</p>

            </div>
        );

        } else {

            return(
                    <div className="containerUtente">
                    <img src={imgAreaPersonale} alt="Login Page" heigth={80} />
                    <p>Bentornat@ {sessionStorage.getItem("username")}! </p>
                    <div className="containerAzioni">
                        
                        <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                            <button type="button" className="btnGoto">
                                <img src={imgLista} alt="Login Page" heigth={50} width={50}/>
                                <span> VAI A {sessionStorage.getItem("nomelista")}</span>
                            </button>
                        </Link>

                        <Link to="/aggiungicose" style={{ textDecoration: 'none' }}>
                            <button type="button" className="btnGoto">
                                <img src={imgAggiungi} alt="Login Page" heigth={50} width={50}/>
                                <span> NUOVI ITEMS</span>
                            </button>
                        </Link>
                        
                        <button type="button" className='btnLogout' onClick={() => this.deleteSession()}>
                            <img src={imgLogout} alt="Log out" height={50} width={50} />
                            <span>LOGOUT</span>
                        </button>
                    </div>
                    
                    </div>
                    
            );
        
        }
    }
}
export default Login;