import React from "react";
import '../stile/App.css';
import '../stile/AccediStile.css';
import Login from '../components/Login.js'


function Accedi() {
    return (
        <div className="accedi">
            <Login />
        </div>
    );
}

export default Accedi;